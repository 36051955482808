<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="bg-left d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to UPC! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- Username -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    placeholder="john"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="loading"
                class="mt-2"
              >
                <template v-if="loading">
                  <Spinner variant="light" />
                </template>

                <template v-else>
                  Sign in
                </template>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Spinner from '@core/components/Spinner'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useToastHandler from '@/services/toastHandler'
import { debounce } from 'debounce'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Spinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      password: '',
      sideImg: require('@/assets/images/logo/logo.png'),
      required,
      loading: false,
    }
  },
  computed: {
    ...mapState('account/login', ['error']),
    ...mapState('auth', ['ability']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    debounce(() => {
      const env = process.env.VUE_APP_ENV_ENVIRONMENT
      if (env !== 'production') return
      const recaptcha = this.$recaptchaInstance
      if (!recaptcha) return
      recaptcha.showBadge()
    }, 2000)()
  },
  beforeDestroy() {
    const recaptcha = this.$recaptchaInstance
    if (!recaptcha) return
    recaptcha.hideBadge()
  },
  methods: {
    ...mapActions({
      loginAsync: 'account/login/loginAsync',
      recaptchaAsync: 'account/verify/recaptchaAsync',
    }),

    async login() {
      const valid = await this.$refs.loginForm.validate()
      if (!valid) return
      this.loading = true
      try {
        const data = await this.verifyCaptcha()
        if (!data) return
        const userData = await this.loginAsync({
          username: this.username,
          password: this.password,
        })

        if (!userData) return

        this.showToastSuccess(
          `Welcome ${userData.username}`,
          `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
        )

        this.$ability.update(this.ability)
        this.$router.push({ name: 'dashboard' })
        // this.$router.replace(getHomeRouteForLoggedInUser(userData.role)).then(() => {
        //   this.showToastSuccess(
        //     `Welcome ${userData.fullName}`,
        //     `You have successfully logged in as ${userData.role}. Now you can start to explore!`
        //   )
        // })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async verifyCaptcha() {
      const env = process.env.VUE_APP_ENV_ENVIRONMENT
      if (env !== 'production') return true
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      const data = await this.recaptchaAsync(token)
      return data
    },
  },
  setup() {
    const { showToastSuccess } = useToastHandler()
    return { showToastSuccess }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
